import { ref, watch } from "vue";

export function getElementWidth(element: any) {
  const workableWidth = ref(0);

  let observer: any = null;

  function disconnect() {
    if (observer !== null) {
      observer.disconnect();
      observer = null;
    }
  }

  function connect(element: any) {
    disconnect();
    observer = new ResizeObserver((entries) => {
      const rect = entries[0]?.contentRect;
      if (rect) {
        workableWidth.value = rect.width;
      }
    });

    observer.observe(element);
  }

  watch(element, (el) => {
    if (el) connect(el);
    else disconnect();
  });

  return {
    workableWidth,
  };
}
